import Vue from 'vue'

export default {
  SET (state, payload) {
    state.internalServiceOrder = Object.assign({}, state.internalServiceOrder, payload) //precisa ser assim para manter a reatividade
  },
  SET_INTERNAL_SERVICE_ORDERS (state, payload) {
    state.internalServiceOrders = payload
  },
  SET_INTERNAL_SERVICE_ORDERS_GRID (state, payload) {
    state.internalServiceOrdersGrid = payload
  },
  STORE (state, payload) {
    state.internalServiceOrders.push(payload) //precisa ser assim para manter a reatividade
    state.internalServiceOrders.sort((b, a) => { return new Date(a.created_at) - new Date(b.created_at) }) //ORDENA POR DATA

    state.internalServiceOrdersGrid.data.push(payload)
    state.internalServiceOrdersGrid.data.sort((b, a) => { return new Date(a.created_at) - new Date(b.created_at) }) //ORDENA POR DATA
    state.internalServiceOrdersGrid.total = state.internalServiceOrdersGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.internalServiceOrders.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.internalServiceOrders, idx, payload)

    const idx1 = state.internalServiceOrdersGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.internalServiceOrdersGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.internalServiceOrders.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.internalServiceOrders.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.internalServiceOrdersGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.internalServiceOrdersGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.internalServiceOrdersGrid.total = state.internalServiceOrdersGrid.total - 1
    }
  }
}
