<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="print" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="deleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    print () {
      this.params.print(this.params)// assim chamo a função de fora
    },
    deleteRecord () {
      this.params.delete(this.params)// assim chamo a função de fora
    }
  }
}
</script>
